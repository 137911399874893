import React, { useEffect, useState } from "react";
import * as S from './SaquesStyle';
import { useDispatch, useSelector } from 'react-redux';
import { getDepositos, getSaques } from '../../redux/actions';
import ValidarCredenciais from "../ValidarCredenciais/ValidarCredenciais";
import ModalNovoSaque from "./ModalNovoSaque";
import { fetchClients } from "../../redux/clients/actions";
import { formatCPF, formatDate } from "../ASSETS/assets";
import Loading from "../Loader";
import * as XLSX from 'xlsx';

export default function Saques() {
    const [search, setSearch] = useState('');
    const [modalAberto, setModalAberto] = useState(false);
    const [modalData, setModalData] = useState({});
    const [modalNovoSaque, setModalNovoSaque] = useState(false);
    const [load, setLoad] = useState(false);
    const [sortOrder, setSortOrder] = useState('Decrescente');
    const [exportFilter, setExportFilter] = useState('all');

    const dispatch = useDispatch();
    const saques = useSelector(state => state.SaquesReducer.saques);

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        if (saques.length === 0) {
            dispatch(getSaques());
        }
    }, [dispatch, saques.length]);

    const handleStatus = (status) => {
        switch (status) {
            case 1:
                return 'Pendente';
            case 2:
                return 'Pago';
            case 3:
                return 'Expirado';
            case 4:
                return 'Cancelado';
            default:
                return 'Indefinido';
        }
    };

    const handleOpenValidarModal = (data) => {
        setModalData(data);
        setModalAberto(true);
    };

    const handleSortOrderChange = event => {
        setSortOrder(event.target.value);
    };

    const handleAccount = (user) => {
        return (
            <S.AccountInfoSelect>
                <option>BANCO: {user.BANCO ? user.BANCO : user.BANK ? user.BANK : 'sem banco'}</option>
                <option>AGÊNCIA: {user.CLIENT_AGENCY ? user.CLIENT_AGENCY : 'sem agência'}</option>
                <option>CONTA: {user.CLIENT_ACCOUNT ? user.CLIENT_ACCOUNT : 'sem conta'}</option>
                <option>TIPO: {user.CLIENT_ACCOUNTTYPE ? user.CLIENT_ACCOUNTTYPE : 'sem definição'}</option>
                <option>PIX: {user.CLIENT_KEYPIX ? formatCPF(user.CLIENT_KEYPIX) : 'sem pix'}</option>
            </S.AccountInfoSelect>
        )
    };

    const sortSaques = (saques) => {
        return saques.sort((a, b) => {
            const dateA = new Date(a.DATASOLICITACAO);
            const dateB = new Date(b.DATASOLICITACAO);
            return sortOrder === 'Crescente' ? dateA - dateB : dateB - dateA;
        });
    };

    const filteredSaques = sortSaques(saques)
        .filter(saque => saque.STATUS === 1 && saque.description !== "Descontar")
        .filter(saque => saque.CLIENT_NAME.toLowerCase().includes(search.toLowerCase()));

    // Lógica de paginação
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredSaques.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(filteredSaques.length / itemsPerPage);

    const handleNextPage = () => setCurrentPage(prev => Math.min(prev + 1, totalPages));
    const handlePreviousPage = () => setCurrentPage(prev => Math.max(prev - 1, 1));

    const handleReload = async () => {
        setLoad(true);
        await dispatch(getSaques());
        await dispatch(fetchClients('recarregar'));
        await dispatch(getDepositos());
        setLoad(false);
    };

    const handleExportToExcel = () => {
        const now = new Date();
        const currentMonth = now.getMonth();
        const currentYear = now.getFullYear();
        const firstDayOfCurrentMonth = new Date(currentYear, currentMonth, 1);
        const firstDayOfPreviousMonth = new Date(currentYear, currentMonth - 1, 1);

        // Filtrando os dados com base na seleção do filtro de exportação
        const filteredData = filteredSaques.filter(user => {
            const dataSolicitacao = new Date(user.DATASOLICITACAO); // Data da solicitação
            if (exportFilter === 'currentMonth') {
                return dataSolicitacao >= firstDayOfCurrentMonth; // Filtrar apenas do mês atual
            } else if (exportFilter === 'previousMonth') {
                return dataSolicitacao < firstDayOfCurrentMonth && dataSolicitacao >= firstDayOfPreviousMonth;
            }
            return true; // Para "all", retorna todos os dados filtrados
        });

        const data = filteredData.map(user => ({
            'CLIENTE': user.CLIENT_NAME,
            'CPF': user.CLIENT_CPF,
            'DATA SOLICITAÇÃO': formatDate(user.DATASOLICITACAO),
            'VALOR': `R$ ${String(user.VALORSOLICITADO).replace(".", ",") || String(user.VALOR).replace(".", ",")}`,
            'VALOR + TAXA': `R$ ${user.VALORSOLICITADO ? (String(user.VALORSOLICITADO - (user.VALORSOLICITADO * 0.04)).replace(".", ",")) : (String(user.VALOR - (user.VALOR * 0.04)).replace(".", ","))}`,
            'BANCO': `${user.BANCO || 'sem banco'}`,
            'AGÊNCIA': `${user.CLIENT_AGENCY || 'sem agência'}`,
            'CONTA': `${user.CLIENT_ACCOUNT || 'sem conta'}`,
            'TIPO': `${user.CLIENT_ACCOUNTTYPE || 'sem definição'}`,
            'PIX': `${user.CLIENT_KEYPIX || 'sem sem'}`,
            'FUNDOS': user.DESCRIPTION === "Saque de Indicação" ? "Indicação" : "Não Informado",
            'APROVADO': handleStatus(user.STATUS)
        }));

        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Saques");

        // Gera o arquivo Excel
        XLSX.writeFile(workbook, 'lista_saques.xlsx');
    };


    return (
        <S.SaquesContainer>
            {modalAberto && (
                <ValidarCredenciais
                    modalData={modalData}
                    setModalAberto={setModalAberto}
                    type="SAQUE"
                />
            )}

            {modalNovoSaque && (
                <ModalNovoSaque handleReload={handleReload} setModalNovoSaque={setModalNovoSaque} />
            )}

            <Loading load={load} />

            <S.SaquesFirstContent>
                <S.AreaTitle>VALIDAR SAQUES</S.AreaTitle>
                <S.AddSaques onClick={() => setModalNovoSaque(true)}>+ REALIZAR NOVO SAQUE</S.AddSaques>
            </S.SaquesFirstContent>

            <S.SaquesContent>
                <S.SearchBar>
                    <input
                        value={search}
                        onChange={e => {
                            setSearch(e.target.value);
                            setCurrentPage(1);
                        }}
                        type="text"
                        placeholder="FILTRAR"
                    />
                </S.SearchBar>

                <S.SaquesTable>
                    <S.AtualizarData>
                        <span onClick={handleReload}>ATUALIZAR</span>
                    </S.AtualizarData>
                    <S.ExtrairEOrdenar>
                        <select value={sortOrder} onChange={handleSortOrderChange}>
                            <option value="Crescente">Crescente</option>
                            <option value="Decrescente">Decrescente</option>
                        </select>

                        <div className="divPaiFilter">
                            <select className="selectExtrairTabela" value={exportFilter} onChange={(e) => setExportFilter(e.target.value)}>
                                <option value="currentMonth">Extrair Mês Atual</option>
                                <option value="previousMonth">Extrair Mês Anterior</option>
                                <option value="all">Extrair Tabela Completa</option>
                            </select>

                            <button className="botaoExtrairTabela" onClick={handleExportToExcel}>
                                Extrair
                            </button>
                        </div>
                    </S.ExtrairEOrdenar>
                    <S.TableContainer>
                        <S.Table>
                            <S.TableHeader>
                                <S.TableRow>
                                    <S.TableHeaderCell>CLIENTE</S.TableHeaderCell>
                                    <S.TableHeaderCell>CPF</S.TableHeaderCell>
                                    <S.TableHeaderCell>DATA SOLICITAÇÃO</S.TableHeaderCell>
                                    <S.TableHeaderCell>VALOR</S.TableHeaderCell>
                                    <S.TableHeaderCell>VALOR + TAXA</S.TableHeaderCell>
                                    <S.TableHeaderCell>CONTA</S.TableHeaderCell>
                                    <S.TableHeaderCell>FUNDOS</S.TableHeaderCell>
                                    <S.TableHeaderCell>APROVADO</S.TableHeaderCell>
                                    <S.TableHeaderCell>OPÇÕES</S.TableHeaderCell>
                                </S.TableRow>
                            </S.TableHeader>
                            <S.TableBody>
                                {currentItems.map((user, index) => (
                                    <S.TableRow key={index}>
                                        <S.TableCell>{user.CLIENT_NAME}</S.TableCell>
                                        <S.TableCell>{user.CLIENT_CPF}</S.TableCell>
                                        <S.TableCell>{formatDate(user.DATASOLICITACAO)}</S.TableCell>
                                        <S.TableCell>R$ {user.VALORSOLICITADO || user.VALOR}</S.TableCell>
                                        <S.TableCell>R$ {user.VALORSOLICITADO ? (user.VALORSOLICITADO - (user.VALORSOLICITADO * 0.04)).toFixed(2) : (user.VALOR - (user.VALOR * 0.04)).toFixed(2)}</S.TableCell>
                                        <S.TableCell>{handleAccount(user)}</S.TableCell>
                                        <S.TableCell>{user.DESCRIPTION === "Saque de Indicação" ? "Indicação" : "Não Informado"}</S.TableCell>
                                        <S.TableCell>{handleStatus(user.STATUS)}</S.TableCell>
                                        <S.TableCell>
                                            <S.OptionsButtons>
                                                <img
                                                    onClick={() => { handleOpenValidarModal(user) }}
                                                    src='pay-ico-saque.png' alt="payIco"
                                                />
                                            </S.OptionsButtons>
                                        </S.TableCell>
                                    </S.TableRow>
                                ))}
                            </S.TableBody>
                        </S.Table>
                    </S.TableContainer>
                </S.SaquesTable>

                {/* Componente de Paginação */}
                <S.Pagination>
                    <S.PaginationButton
                        onClick={handlePreviousPage}
                        disabled={currentPage === 1}
                    >
                        Anterior
                    </S.PaginationButton>
                    <S.PaginationInfo>
                        Página {currentPage} de {totalPages}
                    </S.PaginationInfo>
                    <S.PaginationButton
                        onClick={handleNextPage}
                        disabled={currentPage === totalPages}
                    >
                        Próxima
                    </S.PaginationButton>
                </S.Pagination>
            </S.SaquesContent>
        </S.SaquesContainer>
    );
}
